<script>
import OnlineReportageNewViewVlm from './OnlineReportageNewViewVlm'

export default {
  name: 'OnlineReportageEditViewVlm',
  extends: OnlineReportageNewViewVlm,
  created () {
    this.getOnlineReportage()
  }
}
</script>
